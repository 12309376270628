<template>
  <!-- 品名详情 -->
  <el-dialog class="productNamePopUpLayer" title="品名详情" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
    <el-descriptions :column="2" title>
      <el-descriptions-item label="品种名称">
        {{ heavyDetails.goodsName }}
      </el-descriptions-item>
      <el-descriptions-item label="材质">
        {{ heavyDetails.materials }}
      </el-descriptions-item>
      <el-descriptions-item label="产地">
        {{ heavyDetails.productions }}
      </el-descriptions-item>
      <el-descriptions-item label="规格型号">
        {{ heavyDetails.specs }}
      </el-descriptions-item>
      <el-descriptions-item label="件重(吨)">
        {{ heavyDetails.weights }}
      </el-descriptions-item>
      <el-descriptions-item label="状态">
        {{ heavyDetails.state===0?'禁用':'有效' }}
      </el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { goodsWeightsDetail } from '../../../api/goods'
export default {
  props: {
    id: {
      default: '',
      type: [String, Number]
    }
  },
  data() {
    return {
      heavyDetails: {},
      dialogTableVisible: false
    }
  },
  watch: {
    id: {
      handler(newVal) {
        if (newVal) {
          goodsWeightsDetail(newVal, res => {
            this.heavyDetails = { ...res.data }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
